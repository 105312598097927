import React from 'react';
import './index.css';


export default function Top() {

    return (
        <>
         <div className="header-area">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-6 col-lg-7">
                        <div className="left">
                            <ul>
                                <li>
                                    <i className="flaticon-delivery-truck"></i>
                                    <span> ESTAMOS SEMPRE PRONTOS PARA ABASTECER SUA LOJA</span>
                                </li>
                                {/*<li>
                                    <i className="flaticon-quality"></i>
                                    <span>Best Price Guarantee</span>
                                </li>
                                <li>
                                    <i className="flaticon-call-center"></i>
                                    <span>24/7 Customer Support</span>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-5">
                        <div className="right">
                            {/*<div className="inner">
                                <form>
                                    <select>
                                        <option>Português</option>
                                        <option>English</option>
                                        <option>Deutsch</option>
                                    </select>
                                </form>
                            </div>*/}
                            <div className="inner">
                                <div className="call">
                                    <i className="flaticon-phone-call"></i>
                                    <a href="tel:1236243844"><small>12</small> 3624-3844</a>
                                </div>
                            </div>

                            <div className="inner">
                                <div className="call">
                                    <i className="flaticon-phone-call"></i>
                                    <a href="tel:1230110808"><small>12</small> 3011-0808</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}