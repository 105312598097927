import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './index.css';


export default function Footer() {

    return (
       <>
       <footer className="footer-area pt-100 pb-70">
            <div className="footer-shape">
                <img src="/assets/images/footer-right-shape.png" alt="Shape" />
                <img src="/assets/images/shape5.png" alt="Shape" />
            </div>
            <div className="container">
                <div className="row _justify-content-center">
                    <div className="col-sm-6 col-lg-4">
                        <div className="footer-item">
                            <div className="footer-logo">
                                <LinkContainer to="/">
                                <a className="logo" href="#">
                                    <img src="/assets/images/logo.png" alt="Logo" />
                                </a>
                                </LinkContainer>
                            </div>
                        </div>
                        <div className="social-links">
                            <ul>
                                <li>
                                    <a href="https://instagram.com/comercialwndistribuidora?igshid=YmMyMTA2M2Y=" target="_blank">
                                        <i className='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                {/*<li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-skype'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-youtube'></i>
                                    </a>
                                </li>*/}
                            </ul>
                        </div>
                        
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <div className="footer-item">
                            <div className="footer-logo">
                                <h3>Onde estamos</h3>
                                <ul>
                                    <li>
                                        <i className="flaticon-pin"></i>
                                        <a href="#">AV. José Bonifácio Moreira, 325</a>
                                        <a href="#">Jd. Paulista - Taubaté (SP)</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <div className="footer-item">
                            <div className="footer-logo">
                                <h3>Canais de vendas</h3>
                                <div className="row">
                                    <div className="col-12 col-sm-8 col-lg-8">
                                        <ul>
                                            <li>
                                                <i className="flaticon-email"></i><a href="#"><span className="__cf_email__">vendas@comercialwn.com.br</span></a>
                                            </li>
                                            <li>
                                                <i className="flaticon-phone-call"></i>
                                                <a href="tel:1236243844">12 3624-3844</a>
                                                <a href="tel:1230110808">12 3011-0808</a>
                
                                            </li>
                                        </ul>
                                    </div>
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-sm-6 col-lg-6">
 
                    </div>
                    <div className="col-sm-6 col-lg-6">
                       
                    </div>
                </div>
            </div>
            
        </footer> 

        <div className="copyright-area">
            <div className="container">
                <div className="copyright-item">
                    <p>Copyright ©2022 by <a href="https://procertech.com.br" target="_blank">https://procertech.com.br</a></p>
                </div>
            </div>
        </div>
       </>
    );
}