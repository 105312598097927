import React, { useEffect, useState, useRef }  from 'react';
import './index.css';

export default function Support() {


    return (
        <>
            <div className="support-area pt-100 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-lg-4">
                            <div className="support-item">
                                <i className="bx bxl-whatsapp"></i>
                                <h3>Dúvidas e Suporte</h3>
                                <p>12 99148-2103 / Whatsapp<br />12 99133-7208 / Whatsapp</p>
                                <img src="assets/images/support-shape1.png" alt="Shape" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="support-item">
                                <i className="flaticon-call-center"></i>
                                <h3>Central de Vendas</h3>
                                <p>Segunda a Sexta das 9h as 18h<br />Sábado das 9h as 13h</p>
                                <img src="assets/images/support-shape1.png" alt="Shape" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="support-item">
                                <i className='bx bxs-credit-card'></i>
                                <h3>Pague em até 12x</h3>
                                <p>Parcelamento no cartão de<br /> crédito Visa ou Mastercard</p>
                                <img src="assets/images/support-shape1.png" alt="Shape" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}