import React, { useEffect, useState, useRef }  from 'react';
import './index.css';

export default function Brand() {





    return (
        <>
        <section className="brand-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>Algumas marcas que trabalhamos</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand1.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand2.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand3.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand4.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand5.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand6.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand7.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="brand-item">
                            <a href="#">
                                <img src="assets/images/brand/brand8.png" alt="Brand" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}