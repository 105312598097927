import React, { useEffect, useState, useRef, useContext }  from 'react';
import { Request } from '../../scripts/Request';
import { Currency } from '../../scripts/StringUtils';
import { HubObjects } from '../../scripts/HubObjects';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

import './index.css';
import Breadcrumb from '../Header/Breadcrumb';
import BestSelling from '../Home/BestSelling'
import Lista from './Lista';



export default function Produtos(props) {
    let { Word } = useParams();
    
    const list = [
        {link:"/", name:"Home"},
        {link:"", name:"Lista de produtos"}
    ];


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    return (
        <>
            <Breadcrumb title="Produtos" list={list}  />
            <Lista Word={Word}/>
            <BestSelling />
        </>
    )
}