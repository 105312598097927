import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import Home from './components/Home';
import Produtos from './components/Produtos';

import Layout from './Layout';
import Produto from './components/Produto';
import Cadastro from './components/Cadastro';
import Login from './components/Login';
import Contato from './components/Contato';
import Sobre from './components/Sobre';



export default function RouteManager() {
    useEffect(() => {
      GetServer();
    }, [])



    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/Home" element={<Home />} />
                <Route exact path="/Produtos" element={<Produtos />} />
                <Route exact path="/Produtos/:Word" element={<Produtos />} />
                <Route exact path="/Produto/:ProdutoId" element={<Produto />} />
                <Route exact path="/Produto/:ProdutoId/:Nome" element={<Produto />} />
                <Route exact path="/Cadastro" element={<Cadastro />} />
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Contato" element={<Contato />} />
                <Route exact path="/Sobre" element={<Sobre />} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}