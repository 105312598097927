import React, { useEffect } from 'react';
import './index.css';
import ReactGA from 'react-ga';
import Breadcrumb from '../Header/Breadcrumb';
import Formulario from './Formulario/';


export default function Cadastro() {
    const list = [
        {link:"/", name:"Home"},
        {link:"", name:"Formulário de cadastro"}
    ];


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    return (
        <>
            <Breadcrumb title="Cadastro" list={list}  />
            <Formulario />
        </>
    )
}