import React, {useState} from 'react';
import Footer from './components/Footer';
import Header from './components/Header';

export default function Layout({ children }) {

  return (
      <React.Fragment>
        <Header />
        {children}
        <Footer />
      </React.Fragment>
  )
}