const application_servers_list = [
    "https://comercialwn.com.br/",
    "https://comercialwn.com.br/",
    "https://comercialwn.com.br/",
    "https://comercialwn.com.br/",

    "https://comercialwn.com.br/",
    "https://comercialwn.com.br/",
    "https://comercialwn.com.br/",
    "https://comercialwn.com.br/"
];

export const GetServer = (server_index) => {
    let id_server = rIndex(0, application_servers_list.length-1);
    console.log("id_server", id_server);
    let _server = ((server_index === undefined || server_index === null || server_index === "") ? application_servers_list[id_server] : application_servers_list[server_index]);

    let ourl = window.location.href;
    if (ourl.indexOf("localhost") > -1) {
        window.localStorage.setItem('app_server',_server);
    } else {
        window.localStorage.setItem('app_server', _server);

    }
}



export const AppServer = () => {
    let _server = window.localStorage.getItem('app_server');
    if (_server !== undefined && _server !== null && _server !== "") {
        //console.log("app_server 1", _server);
        return _server;
    } else {
        let id_server = rIndex(0, application_servers_list.length-1);
        _server = application_servers_list[id_server];
        window.localStorage.setItem('app_server', _server);

        if (_server !== undefined && _server !== null && _server !== "") {
            //console.log("app_server 2", _server, id_server);

            return _server;
        } else {
            //console.log("app_server 3", _server);
            return application_servers_list[0];
        }
    }
}


const rIndex = (min, max) => {
    min = Math.floor(min);
    max = Math.ceil(max) + 0.9;
    //return Math.floor(Math.random() * (max - min)) + min;
    return  Math.floor((Math.random() * 1000000)%(max - min)) + min;
}
