/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { RequestGet, RequestPost, Request } from '../../scripts/Request.js';
import InfiniteScroll from "react-infinite-scroll-component";
import StringMask from 'string-mask';
import $ from 'jquery';
import moment from 'moment';
import './FormGenerator.css';
import { UnCurrency, Currency } from '../../scripts/StringUtils';



export const FormGenerator = forwardRef((props, ref) => {
    const [fields, setFields] = useState([]);
    const _loadedRef = useRef(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    //#region Effects
    useEffect(() => {
        Init();
    }, []);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Post(url, args, token = "") { return Post(url, args, token) },
        async Test(url, args, token = "") { return Test(url, args, token) },
        async Select(field, options, url = "", method = "GET",  args = "", token = "") { return Select(field, options, url, method, args, token) },
        async Load(data, field = "", value = "", type = "insert") { return Load(data, field, value, type) },
        async Value(field, value) { return Value(field, value) },
        GetObject(field) { return GetObject(field) },
        SetObject(field, objectValue) { return SetObject(field, objectValue)},
        GetValue(field) { return GetValue(field) },
        GetOptions(field) { return GetOptions(field) },
        IsLoaded() { return IsLoaded()},
        DisableFields(target_fields, enabled) { return DisableFields(target_fields, enabled) },
        Clear() { return Clear()},
        RequiredValidation() { return RequiredValidation() },
    }));
    //#endregion Ref


    //#region Actions
    const DisableFields = (target_fields, enabled = false) => {
        let _fields = [...fields];

        if (target_fields) {
            for(let i=0; i<_fields.length; i++){
                for(let j=0; j<target_fields.length; j++){
                    if(target_fields[j] === _fields[i].name) {
                        _fields[i].disabled = !enabled;
                    }
                }
            }
        } else {
            for(let i=0; i<_fields.length; i++){
                 _fields[i].disabled = !enabled;
                 //$("#" + _fields[i].name).prop("disabled", !enabled);
            }
        }

        setFields(_fields);
    }

    const IsLoaded = () => {
        let _fields = [...fields];
        return _fields.length > 0;
    }


    const Post = async (url, args, token = "") => {
        let _fields = PreProcFields(fields);
        _fields = (args != null) ? Object.assign(_fields, args) : _fields;

        return await Promise.resolve(new Request().Run(url, "POST", _fields, token))
            .then((data) => {
                return data;
            });
    }


    const Test = async (url, args, token = "") => {
        let _fields = PreProcFields(fields);
        _fields = (args != null) ? Object.assign(_fields, args) : _fields;

        console.log(_fields);
    }



    const Select = async (field, options, url, method, args, token) => {
        try {
            let _fields = [...fields];
            let _field = _fields.find(f => f.name === field);
            //console.log(field, options.length);
            if (options.length === 0) {
                options = await new Request().Run(url, method, args, token);
                if (options.length > 0) {
                     _field.value = options[0].id;
                }
                _FieldFunction("on_" + field, options);
            }

            _field.type.options = options;
            setFields(_fields);
        } catch (e) { 
            console.log(field, e.message);
        }
    }


    const Value = (field, value) => {
        try {
            let _fields = [...fields];
            let _field = _fields.find(f => f.name === field);
            _field.value = value;
            setFields(_fields);
        } catch(e) {
            console.log(e.message, field, value);
        }
    }


    const GetObject = (field) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === field);
        return _field;
    }


    const SetObject = (field, objectValue) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name === field);
        if(objectValue.mask) _field.mask = objectValue.mask;
        if(objectValue.title) _field.title = objectValue.title;
        if(objectValue.required!==undefined&&objectValue.required!==null) _field.required = objectValue.required;
        if(objectValue.width) _field.width = objectValue.width;
        if(objectValue.height) _field.height = objectValue.height;
        if(objectValue.type) _field.type = objectValue.type;
        if(objectValue.visible!==undefined&&objectValue.visible!==null) _field.visible = objectValue.visible;
        setFields(_fields);
        return _field;
    }


    const GetValue = (field) => {
        let _fields = [...fields];
       
        let _field = _fields.find(f => f.name === field);

        if (_field.type.view === "select" || _field.type.view === "select_input") {
            let _tmp = (_field.type.options).find(f => parseInt(f.id) === parseInt(_field.value));
            return _tmp
        } else {
            return _field.value || "";
        }
    }


    const GetOptions = (field) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name == field);
        if (_field.type.view == "select" || _field.type.view == "select_input") {
            return _field.type.options;
        } else {
            return ""
        }
    }


    const RequiredValidation = () => {
        let _fields = [...fields];
        for (let i = 0; i < _fields.length; i++) {
            //console.log(_fields[i].name, _fields[i].value);
            if ((_fields[i].value === "" || _fields[i].value === undefined || _fields[i].value === null) && _fields[i].required) {
                //console.log("----------------------------------------------------");
                return false;
            } 
        }
        return true;
    }


    const Init = () => {
        if(!_loadedRef.current) {
            _FieldFunction("OnFormLoading");
            _loadedRef.current = true;
            Promise.resolve(RequestGet(props.url))
                .then((data) => {
                    data.map((item, i) => {
                        if (item.type.view === "select" || item.type.view === "select_input") {
                            try {
                                item.value = item.type.options[0].id;
                            } catch(e) {item.value = ""; }
                        } else if(item.type.view === "checkbox") {
                            item.checked = false;
                        } else {
                            item.value = "";
                        }
                    });
                    setFields(data);
                    _FieldFunction("OnFormLoaded", data);
                });
        }
    }


    const Clear = (formloadDispatch) => {
        let _fields = [...fields];
        _fields.map((item, i) => {
            if (item.type.view === "select" || item.type.view === "select_input") {
                try {
                    item.value = item.type.options[0].id;
                } catch(e) {item.value = ""; }
            } else if(item.type.view === "checkbox") {
                item.checked = false;
            } else {
                item.value = "";
            }
        });
        setFields(_fields);
        if(formloadDispatch) _FieldFunction("OnFormLoaded", _fields);
    }

    const Load = async (data, field, value, type) => {
        let wait_form = setInterval(() => {
            if (fields.length > 0) {
                clearInterval(wait_form);
                let _fields = [...fields];

                if (field == "") {
                    _fields.map((field, i) => {
                        if (field.type.view === "currency") {
                            let _tmp = UnCurrency(data[field.name]);
                            field.value = Currency(data[field.name]);
                        } else {
                            field.value = data[field.name];
                        }
                        
                    });
                } else {
                    let _field = _fields.find(f => f.name === field);
                    if (type === "insert") {
                        _field.type.options = data
                    } else if(type === "append") {
                        _field.type.options.push(data);
                    }
                    if (value != null) {
                        _field.value = value;
                    }
                }
                setFields(_fields);
            }
        }, 300);
    }
    //#endregion Actions

  
    const PreProcFields = (fields) => {
        let obj = {};
        fields.map(item => {
            obj[item.name] = item.value;
        });
        return obj;
    }


    const _HandleChange = (event) => {

        let _fields = [...fields];
        let _field = _fields.find(f => f.name === event.target.id);

        if (event.target.type == "checkbox") {
            _field.checked = event.target.checked;
            setFields(_fields);
            _FieldFunction(event.target.id, event.target.checked);
        } else {
            if (_field.mask != "" && _field.mask != null && _field.mask != undefined) {
                let result1 = event.target.value.replace(/[^\d]+/g, '')
                let formatter = new StringMask(_field.mask);
                let result = formatter.apply(result1);
                _field.value = result;
            } else {
                _field.value = event.target.value;
            }

           
            setFields(_fields);
            if(event.target.id != "alert") {
                _FieldFunction(event.target.id, event.target.value);
            }
        }
    }


    const _HandleCurrencyChange = (event) => {
        let _fields = [...fields];
        let _field = _fields.find(f => f.name == event.target.id);

        var so = 0;
        var o = event.target.value.toString();
        if (o != null && o != undefined) {
            if (o.indexOf("$") > -1) {
                o = o.replace("R$", "");
                o = o.replace(" ", "");
                o = o.replace(",", "");
                o = o.replace(".", "");
                so = eval(o / 100);
            } else {
                so = eval(o / 100);
            }
        }
        if (isNaN(so)) so = 0;

        _field.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(so);
        setFields(_fields);
        _FieldFunction(event.target.id, event.target.value);
    }

    
    const _Height = (field) => {
        if(field.height === undefined || field.height === null || field.height === null){
            if(field.type.view === 'textarea'){
                return "100px";
            }
        } else {
            return (parseInt(field.height) + 40) + "px"
        }
    }


    const _InlineComponent = (field) => {
        if (field.inline === null || field.inline === undefined || field.inline === "" || field.inline === false) return (
            <div style={{ height: _Height(field)}} >
                <label className={'fg-label' + (field.type.view !== 'separator' && field.type.view !== 'separatorbar' && field.type.view !== 'empty_space' ? '' : ' hide')}>{field.title}<em className={!field.required ? 'hide' : ''}>*</em> : <sup className={field.help != '' && field.help != null && field.help != undefined ? '' : 'hide'}> [ <a title={field.helpcontent} onClick={() => _FieldFunction('sup_' + field.name, field.value)}>{field.help}</a> ]</sup></label>

                <div style={{ maxHeight: '35px' }}>
                    {_FieldComponent(field)}
                </div>
            </div>
        )
    }


    const _FieldFunction = (func, field) => {
        props.execRef(func, field);
    }


    const _HandlerPwdReveal = (field) => {
        //$("." + field.name + "-icon").toggleClass("bx bx-low-vision");
        var input = $("#"+ field.name);
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            $("." + field.name + "-icon").removeClass("bx-show");
            $("." + field.name + "-icon").addClass("bx-hide");
        } else {
            input.attr("type", "password");
            $("." + field.name + "-icon").removeClass("bx-hide");
            $("." + field.name + "-icon").addClass("bx-show");
        }
    }

    const _FieldComponent = (field) => {
        switch (field.type.view) {
            case 'empty_space':
                return (
                    <div className={field.name} id={field.name}></div>
                )
            case 'separator':
                return (
                    <div>
                        <hr className={field.name} />
                    </div>
                )
            case 'separatorbar':
                return (
                    <div className={'ibox-content ibox-heading ' + field.name} style={{ padding: '10px', fontSize: '14px' }}>
                        <small><i className="fa fa-stack-exchange"></i> {field.title}</small>
                    </div>
                )
            case 'input':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="text" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                    </div>
                )
            case 'currency':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="text" required={field.required} value={field.value} onChange={_HandleCurrencyChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                    </div>
                )

            case 'number':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="number" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'date':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="date" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'datetime':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="datetime-local" required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'password':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="password"  required={field.required} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)} />
                        <span className={'bx bx-show ' + field.name +'-icon'}  onClick={() => _HandlerPwdReveal(field)} style={{ float: 'right', marginRight: '10px', marginTop: '-34px', position: 'relative', cursor: 'pointer', fontSize: '23px', zIndex: 2}}></span>
                    </div>
                )

            case 'checkbox':
                return (
                    <div>
                        <input id={field.name} name={field.name} style={{ width: '25px', height: '25px' }} className={'form-control fg-input ' + field.name} type="checkbox" required={field.required} checked={field.value} onChange={_HandleChange} disabled={field.disabled} />
                    </div>
                )

            case 'textarea':
                return (
                    <div>
                        <textarea id={field.name} name={field.name} style={{ height: (field.height || '100px') }} className={'form-control fg-input ' + field.name} value={field.value} onChange={_HandleChange} disabled={field.disabled} onBlur={() => _FieldFunction('blur_' + field.name, field.value)}>{field.value}</textarea>
                    </div>
                )

            case 'select':
                return (
                    <div className="select">
                        <select id={field.name} name={field.name} className={'form-control fg-input ' + field.name} value={field.value} onChange={_HandleChange} disabled={field.disabled} required={field.required}>
                            {field.type.options.map((option, i) => (
                                <option key={'opt_' + i} value={option.id} selected={option.selected == true}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                )

            case 'select_input':
                return (
                    <div className="select">
                        <table border="0" cellPadding="0" cellSpacing="0" style={{ width: '100%', margin: 0, padding: 0 }}>
                            <tbody>
                                <tr style={{ margin: 0, padding: 0 }}>
                                    <td style={{ width: '88%', margin: 0, padding: 0 }}>
                                        <select id={field.name} name={field.name} style={{ width: '100%', margin: 0, padding: 0, height: '35px' }} className={'form-control fg-input ' + field.name} aria-label={field.name} aria-describedby="basic-addon2" value={field.value} disabled={field.disabled} onChange={_HandleChange} required={field.required}>
                                            {field.type.options.map((option, i) => (
                                                <option key={'opti_' + i} value={option.id} selected={option.selected == true}>{option.name}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{ width: '12%', margin: 0, padding: 0 }}>
                                        <button className="btn btn-white" style={{ width: '100%', margin: 0, padding: 0, height: '35px' }} type="button" onClick={() => _FieldFunction('new_' + field.name, field.value)}>Novo</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {fields.map((field, i) => (
                <div key={'field_' + i}>
                    <div id={'_' + field.name} className="form-group pull-left" style={{ width: field.width || 0, padding: '3px', marginTop: field.margintop || 0, display: (field.visible === false ? 'none' : 'block') }}>
                        {_InlineComponent(field)}
                    </div>
                </div>

            ))}
            <div className="clear"></div>
        </div>
    )
});


