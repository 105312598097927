import React, { useEffect, useState, useRef } from 'react';
import './index.css';
import { Cache } from '../../scripts/Cache';
import ReactGA from 'react-ga';

import Banner from './Banner';
import Products from './Products';
import Brand from './Brand';
import BestSelling from './BestSelling';
import Support from './Support';


export default function Home() {
    const [pageLoading, setPageLoading] = useState(false);

    //#region Effects
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      Cache.Remove("search");

      window.$('html, body').animate({
        scrollTop: window.$("body").offset().top
    }, 500);
    }, []);

    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects

    return (
      <>
        <Banner />
        <Products />
        <Support />
        <Brand />
        <BestSelling />
       
      </>
    );
}