/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect,useContext } from 'react';
import { FormGenerator } from '../../Form/FormGenerator';
import { Uuid, RemoveAcentos, Currency, IsCPF } from '../../../scripts/StringUtils';
import { Cache } from '../../../scripts/Cache.js';
import { LinkContainer } from 'react-router-bootstrap';
import { Request } from '../../../scripts/Request';
import { useNavigate } from 'react-router-dom';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { Context } from '../../../Context/AuthContext'
import './index.css';


export default function Formulario() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [saveable, setSaveable] = useState(true);
    const [input, setInput] = useState({});


    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _latlngRef = useRef({latitude: "", longitude:""});;

    const navigate = useNavigate();



    //#region Effects
    useEffect(() => {
        window.$('html, body').animate({
            scrollTop: window.$("body").offset().top
        }, 500);
    }, []);

    
    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
   
    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                
            }, 200);
        } catch(e) { }
        setPageLoading(false);
    }



    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
      
        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }


        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Contato/Send", {}, "");
        setPageLoading(false);
        

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Mensagem enviada com sucesso!", "success");
            setTimeout(() => { 
                if(user.token === '') {
                    navigate('/Login'); 
                } else {
                    navigate('/');
                }
            }, 300);
        
        } else {
            window.swal("Alerta", "Não foi possível cadastrar o usuário", "warning");
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers

    //#endregion Handlers







    return (
        <>

        <div className="Formulario user-area ptb-100">
            <div className="container">
                <div className="user-item">
                    <div className="row">
                        <div className="col-sm-6 col-lg-12">
                            <form name="fuser" onSubmit={(event) => _HandleSubmit('rcontato', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                <FormGenerator ref={_formUserRef} name={'contato'} url={'/forms/contato.json'} execRef={Exec}></FormGenerator>
                                <div style={{clear:'both'}}></div>
                                <br />

                                <button type="submit" className="btn common-btn">
                                    Enviar Mensagem
                                    <img src="/assets/images/shape1.png" alt="Shape" />
                                    <img src="/assets/images/shape2.png" alt="Shape" />
                                </button>
                            </form>
                        </div>
                      
                    </div>
                    <hr />
 
                  
                </div>
            </div>
        </div>
       </>
    )
}
