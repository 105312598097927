import React from 'react';


export default function Info() {

    return (
        <>
             <section className="about-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="top">
                                    <h2>Missão</h2>
                                    <p>Oferecer à excelência na distribuição de material para construção, buscando 
                                    sempre ter produtos de qualidade e inovadores que atenda a necessidade de 
                                    nossos clientes a um preço justo, garantindo a sustentabilidade do negócio.</p><br />
                                    <h2>Visão</h2>
                                    <p>Ser reconhecida no mercado como a empresa que apresenta eficiência e 
                                    eficácia com ótimo atendimento, com isso perpetuando nosso negócio cada 
                                    vez mais.</p><br />
                                    <h2>Valores</h2>
                                    <p>Buscar sempre a melhoria continua, comprometido com a ética, valorizando 
                                    colaboradores, clientes e fornecedores.</p>
                                </div>
                                <div className="middle">
                                    <ul>
                                        <li>
                                            <img src="/assets/images/about-icon1.png" alt="Icon" />
                                            <h3>10+ Anos de Experiência</h3>
                                        </li>
                                        <li>
                                            <img src="/assets/images/about-icon2.png" alt="Icon" />
                                            <h3>Atendimento Personalizado</h3>
                                        </li>
                                        <li>
                                            <img src="/assets/images/about-icon3.png" alt="Icon" />
                                            <h3>Disponibilidade de Estoque</h3>
                                        </li>
                                        <li>
                                            <img src="/assets/images/about-icon4.png" alt="Icon" />
                                            <h3>Abastecimento sempre Ágil </h3>
                                        </li>
                                    </ul>
                                    <h2>WN e sua História</h2>
                                    <p>
                                        No ano de 2000, Weverton iniciou sua história no mercado de materiais de construção, começou como repositor de estoque
                                        de vendas e com seu empenho e trabalho árduo chegou a cargo de gerente geral.
                                    </p>
                                    <p>
                                        Weverton sonhava alto, queria abrir seu próprio negócio, com experiência e conhecimento na área faltava apenas um empurrão, e ele 
                                        veio com o incentivo de sua esposa Nádia.<br /> Unindo toda a sua experiência com a parceria de sua esposa, surgiu então a 
                                        Comercial WN no final de 2011.
                                    </p>
                                    <p>
                                        A WN começou com apenas 6 produtos, pisos e revestimentos para atuar na área de distribuição, um funcionário e um representante
                                        comercial, a estrutura era de apenas 140m<sup>2</sup>.
                                    </p>
                                    <p>
                                        Dez anos se passaram e hoje a WN conta com mais de 4 mil itens em seu estoque, nas categorias: Cobertura, Elétrica, EPIs, Esquadrias, 
                                        Ferragens, Ferramentas, Sacarias, Hidráulica, Utilidades Domésticas e não poderia faltar os Pisos e Revestimentos. Sua área de atuação 
                                        é no Vale do Paraíba, Serra da Mantiqueira e Litoral Norte.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="assets/images/about-main1.jpg" alt="About" />
                                <img src="assets/images/about-main2.jpg" alt="About" />
                                <img src="assets/images/about-main3.jpg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}