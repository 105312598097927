/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext, useCallback }  from 'react';
import { Request } from '../../../scripts/Request';
import { Currency, UrlFy } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { LinkContainer } from 'react-router-bootstrap';
import { Context } from '../../../Context/AuthContext'
import { Cache } from '../../../scripts/Cache';
import { useNavigate } from 'react-router-dom';
import './index.css';


export default function Products() {
    const component_name = "home/products";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [search, setSearch]= useState({categoria_id:"", word: ""});
    const [list, setList] = useState([]);
    const [categorias, setCategorias] = useState([]);

    const loadedRef = useRef(false);
    const timerIdleRef = useRef(new Date());
    const userRef = useRef(user);

    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { 
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadCategorias();
                LoadProdutos();
                RegisterHub(); 
            }
         }, 200);

       

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    //console.log(Cache.JGet("search"));
                    Cache.Remove("search");
                    //console.log(Cache.JGet("search"));
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    

    useEffect(() => {
        userRef.current = user;
        //console.log(1, userRef.current);
    }, [user]);
    //#endregion Effects


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("HOME_PRODUCTS_SEARCH", search => {
            LoadProdutos(search);
        }, component_name);
    }

    const AddFavorite = (produto) => {
        let _list = [...list];
        let _value = (produto.favorite === 0 ? 1: 0);
        _list.find(p => p.produto_id === produto.produto_id).favorite = _value;
        setList(_list);

        let wait_observer = setInterval(()=> {
            if(HubObjects.ObserverExists("ADD_FAVORITE_OBSERVER")) {
                clearInterval(wait_observer);
                HubObjects.Notify("ADD_FAVORITE_OBSERVER", produto.produto_id, (_value===0?-1:1));
            }
        }, 100);
    }
    //#endregion HubObjects


    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Home/Categorias", "GET", "", userRef.current.token, 3600*1000))
            .then((data) => {
                //console.log(data);
                setCategorias(data);
            }).catch(() => {
             
            });
    }


    const LoadProdutos = (search = {categoria_id: "", word:""}) => {
        //console.log(2, userRef.current);
        setSearch(search);
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Home/Produtos", "POST", search, userRef.current.token, 0*1000))
         .then((data) => {
                setList(data);
                setPageLoading(false);
                if(search.categoria_id !== "" || search.word !== "") {
                    window.$('html, body').animate({
                        scrollTop: window.$("#produtos_div").offset().top
                    }, 500);
                }
         }).catch(() => {
            setPageLoading(false);
         });
     };
    //#endregion Loaders


    //#region Handlers
     const _HandleGoToProdutos = () => {
        let _search = Object.assign({}, search);
        if(_search.word !== "") {
            navigate("/Produtos/"+_search.word);
        } else {
            navigate("/Produtos");
        }
     }
    //#endregion Handlers

    return (
        <>
            <div id="produtos_div" className="products-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="sorting-menu">
                                <ul className="justify-content-center">
                                    <li className={'filter ' + (search.categoria_id===''?'active':'')} onClick={() => {LoadProdutos({categoria_id: "", word: ""})}}>
                                        <div className="products-thumb">
                                            <img src="assets/images/products/shape1.png" alt="Shape" />
                                            <img src="assets/images/products/shape2.png" alt="Shape" />
                                            <i className='bx bx-carousel'></i>
                                            <span>Todos</span>
                                        </div>
                                    </li>   

                                    {
                                        categorias.length > 0 ? 
                                            categorias.map((item, i) => (
                                             <li key={'cat_'+i} className={'filter ' + (search.categoria_id===item.categoria_id?'active':'')} onClick={() => {LoadProdutos({categoria_id: item.categoria_id, word: ""})}}>
                                                <div className="products-thumb">
                                                    <img src="assets/images/products/shape1.png" alt="Shape" />
                                                    <img src="assets/images/products/shape2.png" alt="Shape" />
                                                    <i className={item.icon}></i>
                                                    <span>{item.nome}</span>
                                                </div>
                                             </li>
                                            ))
                                        : (<></>)
                                    }

                                    {/*<li className="filter">
                                        <div className="products-thumb">
                                            <img src="assets/images/products/shape1.png" alt="Shape" />
                                            <img src="assets/images/products/shape2.png" alt="Shape" />
                                            <i className='bx bx-carousel'></i>
                                            <span>Todos</span>
                                        </div>
                                    </li>*/} 
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div id="Container" className="row justify-content-center">

                                {
                                    list.length > 0 ?
                                        list.map((item, i) => (
                                            <div key={'prod'+i} className="col-sm-6 col-lg-4 _mix armchair center-table">
                                                <div className="products-item">
                                                    <div className="top">
                                                        <a className={item.favorite===0?'wishlist':'wishlist_selected'}  onClick={() => AddFavorite(item)}>
                                                            <i className='bx bx-heart'></i>
                                                        </a>
                                                        <img src={'https://painel.comercialwn.com.br/db/'+(item.foto===''?'semfoto.jpg':item.foto)}  alt="Products" />
                                                        <div className="inner">
                                                            <h3>
                                                                <LinkContainer to={'/Produto/'+item.produto_id+'/'+UrlFy(item.descricao)}>
                                                                <a>{item.descricao}</a>
                                                                </LinkContainer>
                                                            </h3>
                                                            <span className={(item.preco===0?'hide':'')}>{Currency(item.preco)}</span>
                                                            <span className={(item.preco!==0?'hide':'')}><small>Preço apenas para cadastrados</small></span>
                                                        </div>
                                                    </div>
                                                    <div className="bottom">
                                                        <LinkContainer to={'/Produto/'+item.produto_id+'/'+UrlFy(item.descricao)}>
                                                        <a className="cart-text" href="#">Mais Detalhes</a>
                                                        </LinkContainer>
                                                        <i className='bx bx-plus'></i>
                                                    </div>
                                                </div>
                                            </div>
                                    ))
                                    : (<></>)
                                }



                            </div>
                            <div className="text-center">
                               
                                <a className="common-btn" href="#" onClick={_HandleGoToProdutos}>
                                    Ir para a lista de produtos
                                    <img src="assets/images/shape1.png" alt="Shape" />
                                    <img src="assets/images/shape2.png" alt="Shape" />
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}