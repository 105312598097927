import React, { useEffect, useState, useRef, useContext, useCallback }  from 'react';
import { Context } from '../../../Context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom';
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';


export default function Info(props) {
    const component_name = "produto";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [info, setInfo] = useState(null);

    const loadedRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { ProdutoId } = props;


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { 
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadProduto();
                RegisterHub(); 
            }
         }, 200);

        window.$('html, body').animate({
            scrollTop: window.$("body").offset().top
        }, 500);

        return () => {
           
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadProduto = () => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Produtos/Load?produto_id=" + ProdutoId, "GET", "", user.token, 0*1000))
         .then((data) => {
            if(data.fotos.length === 0) {
                data.fotos.push({foto_id:"", foto:""}) 
            }
            setInfo(data);
            setPageLoading(false);
            setTimeout(() => {
                window.$('.image-slides').owlCarousel({loop:true,nav:false,thumbs:true,dots:false,thumbsPrerendered:true,autoplayHoverPause:true,autoplay:true,items:1,navText:['<i class="bx bx-chevron-left" aria-hidden="true"></i>','<i class="bx bx-chevron-right" aria-hidden="true"></i>']});
            }, 100);
         }).catch(() => {
            setPageLoading(false);
         });
     };
    //#endregion Loaders


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("PRODUTO_UPDATE", produto_id => {
            ProdutoId = produto_id;
            LoadProduto();

            window.$('html, body').animate({
                scrollTop: window.$("body").offset().top
            }, 500);
        }, component_name);
    }

    const AddFavorite = (produto) => {
        let _value = (produto.favorite === 0 ? 1: 0)
        produto.favorite = _value;
        setInfo(produto);

        let wait_observer = setInterval(()=> {
            if(HubObjects.ObserverExists("ADD_FAVORITE_OBSERVER")) {
                clearInterval(wait_observer);
                HubObjects.Notify("ADD_FAVORITE_OBSERVER", produto.produto_id, (_value===0?-1:1));
            }

            forceUpdate();
        }, 100);
    }
    //#endregion HubObjects


    return (
        <>
            <div className="Produto product-details-area ptb-100">
                <div className="container">
                    <div className="top">
                        <div className="row _align-items-center">
                            <div className="col-lg-8">
                                <div className="outer">
                                    <div className="row">
                                        <div className="col-sm-3 col-lg-3">
                                            <div className="owl-thumbs" data-slider-id="1">
                                                {
                                                    info !== null ? 
                                                        info.fotos.length > 0 ?
                                                            info.fotos.map((item, i) =>(
                                                                <div key={'ft'+i} className="item owl-thumb-item">
                                                                    <div className="top-img">
                                                                        <img src={'https://painel.comercialwn.com.br/db/'+(item.foto===''?'semfoto.jpg':item.foto)}  alt="Product" />
                                                                    </div>
                                                                </div>
                                                            ))
                                                        : (<></>)
                                                    : (<></>)
                                                }

                                            </div>
                                        </div>
                                        <div className="col-sm-9 col-lg-9">
                                            <div className="image-slides owl-carousel owl-theme" data-slider-id="1">
                                                {
                                                   info !== null ? 
                                                        info.fotos.length > 0 ?
                                                            info.fotos.map((item, i) =>(
                                                                <div key={'fot'+i} className="top-img">
                                                                    <img src={'https://painel.comercialwn.com.br/db/'+(item.foto===''?'semfoto.jpg':item.foto)}  alt="Product" />
                                                                </div>  
                                                            ))
                                                        : (<></>)
                                                    : (<></>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="top-content">
                                    <h2>{info?.descricao}</h2>
                                    <ul className="reviews">
                                         {
                                             info !== null ? 
                                                !isNaN(info.stars) ?
                                                    [1,2,3,4,5].map((item, i) =>(
                                                        <li key={'star'+i}>
                                                            <i className={'bx bxs-star' + (info.stars >= item?'checked':'')}></i>
                                                        </li>
                                                    ))
                                                : (<></>)
                                            : (<></>)
                                         }           
                                        <li>
                                            <h3 style={{color:'#ED2127'}}>{info?.preco > 0 ? Currency(info?.preco) : ""}</h3>
                                            <h5 style={{color:'#ED2127'}} className={info?.preco !== 0?'hide':''}>Preço disponível para cadastrados</h5>
                                        </li>
                                    </ul>
                                    <p>{info?.addinfo}</p>
                                    <ul className="tag">
                                        <li>Categoria: <span>{info?.categoria}</span></li>
                                        <li>Código: <span>{info?.codigo}</span></li>
                                        <li>Consulte: <span>Disponibilidade pela centrar de vendas</span></li>
                                    </ul>
                                    
                                    <a className="wishlist-btn" onClick={() => AddFavorite(info)}>
                                        <i className={'bx ' + (info?.favorite===1?'bxs-heart':'bx-heart')}></i>
                                        {info?.favorite===1?'Item na sua lista de favoritos':'Adicionar aos favoritos'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                   <hr />
                </div>
            </div>
        </>
    )
}