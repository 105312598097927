import React, { useEffect, useState, useRef }  from 'react';
import { Context } from '../../Context/AuthContext'
import './index.css';
import Menu from './Menu';

import Search from './Search';
import Top from './Top';




export default function Header() {

    return (
        <>
            <Top />
            <Search />
            <Menu />
        </>
    );
}