import { useState, useEffect, useRef } from 'react';
import { Request } from '../../scripts/Request';
import { useNavigate } from 'react-router-dom';



export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ cliente_id: "", name: "", token: "", lastAccess: "", nivel: "", code: "", validade: "" });
    const authRef = useRef(false);
    const tokenRef = useRef("");
    const navigate = useNavigate();



    useEffect(() => {
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;
            tokenRef.current = _u.token;
        }
        setLoading(false);
    }, []);


    async function UserValidate() {
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;;
            tokenRef.current = _u.token;
        }
        setLoading(false);

        return JSON.parse(_user);
    }

    async function handleLogin(form) {
        setLoading(true);
        const user = await Promise.resolve(new Request().Run("api/Auth/Login", "Post", form, ""))
            .then((data) => {
                return data;
            });

        setLoading(false);

        if (user.cliente_id === 0) {
            if(user.nome === "exists") {
                window.swal("Acesso não autorizado", "Usuário cadastrado esperando aprovação", "warning");
            } else {
                window.swal("Acesso não autorizado", "Senha Incorreta", "warning");
            }
        } else {
            localStorage.setItem('user', JSON.stringify(user));
            setAuthenticated(true);
            authRef.current = true;
            setUser(user);
            tokenRef.current = user.token;
            navigate('/');
        }
    }


    async function handleLogout(reload) {
        setAuthenticated(false);
        authRef.current = false;
        setUser({ cliente_id: "", name: "", token: "", lastAccess: "", nivel: "", code: "", validade: "" });
        localStorage.removeItem('user');
        if(!reload) navigate('/');
    }


    return { authenticated, loading, handleLogin, handleLogout, user, UserValidate };
}