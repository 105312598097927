import React, { useEffect, useState, useRef, useContext }  from 'react';
import { Context } from '../../Context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';


import Breadcrumb from '../Header/Breadcrumb';
import Info from './Info';



export default function Sobre() {


    const list = [
        {link:"/", name:"Home"},
        {link:"", name:"Sobra a ComercialWN"}
    ];

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    return (
        <>
            <Breadcrumb title="Sobre nós" list={list}  />
            <Info />
        </>
    )
}