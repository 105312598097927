import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './index.css';


export default function Breadcrumb({...props}) {
    const { title, list } = props;


    return (
        <div className="Breadcrumb page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="title-content">
                        <h2>{title}</h2>
                            <ul>
                                {
                                    list.map((item, i) => (
                                        item.link === "" ? (<li key={'bc'+i}><a href="#">{item.name}</a></li>) 
                                        : (<li key={'bc'+i}><LinkContainer to={item.link}><a href="#">{item.name}</a></LinkContainer> <i className="fa fa-angle-right"></i></li>)
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="title-img">
                <img src="/assets/images/transparent.png" alt="About" />
                <img src="/assets/images/shape16.png" alt="Shape"  style={{opacity:0.8}}/>
                <img src="/assets/images/shape17.png" alt="Shape"  style={{opacity:0.8}}/>
                <img src="/assets/images/shape18.png" alt="Shape" style={{opacity:0.2}}/>
             </div>
        </div>
    )
}