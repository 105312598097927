import React, { useEffect, useState, useRef }  from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Request } from '../../../scripts/Request';
import './index.css';


export default function Banner() {
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);

    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            LoadBanners();
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.$('.loader').fadeIn(500);
          } else {
            window.$('.loader').fadeOut(500);
          }
     }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadBanners = () => {
        Promise.resolve(new Request().Run("api/Home/Banners", "GET", "", "", 3600*1000))
        .then((data) => {
            setList(data);

            setTimeout(() => {
                window.$('.banner-slider').owlCarousel({
                    items:1,
                    loop:true,
                    margin:15,
                    nav:true,
                    dots:false,
                    smartSpeed:1000,
                    autoplay:true,
                    autoplayTimeout:4000,
                    autoplayHoverPause:true,
                    navText:["<i class='bx bx-left-arrow-alt'></i>","<i class='bx bx-right-arrow-alt'></i>"],});
            }, 100);
        }).catch(() => {
         
        });
    }
    //#endregion Loaders



    return (
        <>
            <div className="banner-area-two">
                <div className="banner-slider owl-theme owl-carousel">
                    {
                        list.length > 0 ?
                            list.map((item, i) => (
                                <div key={'banner'+i} className="banner-item">
                                     <div className="d-table">
                                        <div className="d-table-cell">
                                            <div className="container">
                                                <div className="banner-content">
                                                    <h1><span dangerouslySetInnerHTML={{ __html: item.titulo }}></span></h1>
                                                    <p><span dangerouslySetInnerHTML={{ __html: item.descricao }}></span></p>
                                                    <LinkContainer to={item.link}>
                                                    <a className="common-btn" href="#">
                                                        Saiba Mais
                                                        <img src="assets/images/shape1.png" alt="Shape" />
                                                        <img src="assets/images/shape2.png" alt="Shape" />
                                                    </a>
                                                    </LinkContainer>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="banner-img">
                                            <img src={'https://painel.comercialwn.com.br/db/'+item.img} alt="Banner" />
                                            <img src="assets/images/banner/banner-shape1.png" alt="Shape" />
                                        </div>
                                    </div>


                                </div>
                            ))
                        : (<></>)
                    }
                </div>
            </div>
        </>
    );
}