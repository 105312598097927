import React, { useEffect } from 'react';
import './index.css';
import ReactGA from 'react-ga';
import Breadcrumb from '../Header/Breadcrumb';
import SignIn from './SignIn';


export default function Login() {
    const list = [
        {link:"/", name:"Home"},
        {link:"", name:"Seu Acesso"}
    ];


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);




    return (
        <>
            <Breadcrumb title="Login" list={list}  />
            <SignIn />
        </>
    )
}