import React, { useState, useEffect, useRef, useContext } from 'react';
import { LinkContainer } from 'react-router-bootstrap'
import { Context } from '../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Cache } from '../../../scripts/Cache.js';
import { Request } from '../../../scripts/Request';
import './index.css';

export default function SignIn() {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rememberPwd, setRememberPwd] = useState(false);
    const [state, setState] = useState({
        login: "",
        password: "",
        codigo: "",
        token: ""
    });

    const rememberRef = useRef(false);
    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        window.$('html, body').animate({
            scrollTop: window.$("body").offset().top
        }, 500);

        const _userpwd = Cache.JGet("userpwd");

        try {
            if(_userpwd.login !== "") {
                setState({ login: _userpwd.login, password: _userpwd.password });
                rememberRef.current = true;
                setRememberPwd(true);
            }
        } catch(err) { }
    }, []);


    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects


    
    //#region Handlers
    const _HandlerChange = (event) => {
        const { id, value } = event.target;
        const _state = Object.assign({}, state);

        if (event.target.type === "checkbox") {
            rememberRef.current = event.target.checked;
            setRememberPwd(event.target.checked);
        } else {
            _state[id] = value;
        }

        setState(_state);
    }


    const _handleLogin = (event) => {
        event.preventDefault();
        const _state = Object.assign({}, state);
        const _remember = rememberRef.current; //Object.assign({}, rememberPwd);
        const _userpwd = Cache.JGet("userpwd");//localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password });

        if(_remember){
            //console.log(1, _remember);
            Cache.JSet("userpwd", { login: _state.login, password: _state.password });
        } else {
            //console.log(2, _remember);
            Cache.JSet("userpwd", { login: "", password: "" });
        }

        handleLogin(state);
    };


    const _HandleStatus = (status) => {
     
        let _state = Object.assign({}, state);

        if(status === 1) {
            setPage(status);
        } else if(status === 2) {
            Promise.resolve(new Request().Run("api/Cadastro/SendCode", "POST", _state, "", 0*1000))
            .then((data) => {
                if(data.toString() !== "error") {
                    setPage(status);
                } else {
                    window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
                }
            }).catch(() => {
            
            });
        } else if(status === 3) {
            Promise.resolve(new Request().Run("api/Cadastro/CheckCode", "POST", _state, "", 0*1000))
            .then((data) => {
                if(data.toString() !== "error") {
                    _state.token = data;
                    setState(_state);
                    setPage(status);
                } else {
                    window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
                }
                
            }).catch(() => {
            
            });
        }
           
    }


    const SaveNewPwd = () => {
        let _state = Object.assign({}, state);

        Promise.resolve(new Request().Run("api/Cadastro/SaveNewPwd", "POST", _state, "", 0*1000))
        .then((data) => {
            window.swal("Sucesso", "Nova senha salva com sucesso", "success");
            navigate("/");
        }).catch(() => {
        
        });

    }
    //#endregion Handlers




    return (
        <div className="user-area ptb-100">
            <div className="container">
                <div className="user-item">
                    { 
                        page === 0 ? (
                            <>
                            <div className="form-group">
                                <label>Digite seu e-mail</label><br />
                                <input type="text" className="form-control rounded-xs" id="login" placeholder="E-mail" value={state.login} onChange={_HandlerChange} />
                            </div>
                            <div className="form-group">
                                <label>Digite sua senha</label><br />
                                <input type="password" className="form-control rounded-xs" id="password" placeholder="Senha" value={state.password} onChange={_HandlerChange} />
                            </div>
                            <button type="button" className="btn common-btn" onClick={_handleLogin}>
                                Entrar
                                <img src="/assets/images/shape1.png" alt="Shape" />
                                <img src="/assets/images/shape2.png" alt="Shape" />
                            </button>
                            <div className="_form-check _form-check-custom">
                            <label className="form-check-label font-14" for="remember"><input className="form-check-input" type="checkbox" name="type" id="remember" onClick={_HandlerChange} checked={rememberPwd} style={{width:'21px', height:'21px'}}/>&nbsp; Lembrar Senha</label>
                            </div>
                            <h4>Ou</h4>
                            <ul>
                                <li>
                                    <a href="#" onClick={() => _HandleStatus(1)}>
                                        Esqueci minha senha
                                    </a>
                                </li>
                            </ul>
                            <h5>Ainda não tem cadastro? <LinkContainer to="/Cadastro"><a href="#">Cadastre-se</a></LinkContainer></h5>
                            </>
                        ) : page === 1 ? (
                            <>
                                <h2>Recuperar senha</h2>
                                <div className="form-group">
                                <label>Digite seu e-mail</label><br />
                                <input type="text" className="form-control rounded-xs" id="login" placeholder="" value={state.login} onChange={_HandlerChange} />
                                <br />
                                <ul>
                                    <li>
                                        <a href="#" onClick={() => _HandleStatus(2)}>
                                            Enviar código de recuperação
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            </>
                        ) : page === 2 ? (
                            <>
                                <h2>Confirmar código</h2>
                                <div className="form-group">
                                <label>Digite seu código de recuperação</label><br />
                                <input type="text" className="form-control rounded-xs" id="codigo" placeholder="" value={state.codigo} onChange={_HandlerChange} />
                                <br />
                                <ul>
                                    <li>
                                        <a href="#" onClick={() => _HandleStatus(3)}>
                                            Validar código
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            </>
                        ) : (<>
                            <div className="form-group">
                                <label>Digite sua nova senha</label><br />
                                <input type="password" className="form-control rounded-xs" id="password" placeholder="Senha" value={state.password} onChange={_HandlerChange} />
                            </div>
                            <button type="button" className="btn common-btn" onClick={SaveNewPwd}>
                                Salvar nova senha
                                <img src="/assets/images/shape1.png" alt="Shape" />
                                <img src="/assets/images/shape2.png" alt="Shape" />
                            </button>
                        </>)
                    }
                </div>
            </div>
    </div>
    )
}