import React, { useEffect, useState, useRef, useContext }  from 'react';
import { Request } from '../../../scripts/Request';
import { Currency, UrlFy } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { LinkContainer } from 'react-router-bootstrap';
import { Context } from '../../../Context/AuthContext'
import './index.css';

export default function BestSelling() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);

    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            setTimeout(()=> {
                LoadDestaques();
            }, 300);
        }
    }, []);

     
    useEffect(() => {
        if(pageLoading){
            window.$('.loader').fadeIn(500);
          } else {
            window.$('.loader').fadeOut(500);
          }
     }, [pageLoading])
    //#endregion Effects


     //#region Loaders
     const LoadDestaques = () => {
       Promise.resolve(new Request().Run("api/Home/Destaques", "GET", "", user.token, 0*1000))
        .then((data) => {
            setList(data);
        }).catch(() => {
         
        });
    };
    //#endregion Loaders


    //#region Handlers
    const _HandleProdutoUpdate = (produto_id) => {
        HubObjects.Exec("PRODUTO_UPDATE", produto_id);
    }
    //#endregion Handlers


    //#region HubObjects
    const AddFavorite = (produto) => {
        let _list = [...list];
        let _value = (produto.favorite === 0 ? 1: 0);
        _list.find(p => p.produto_id === produto.produto_id).favorite = _value;
        setList(_list);

        let wait_observer = setInterval(()=> {
            if(HubObjects.ObserverExists("ADD_FAVORITE_OBSERVER")) {
                clearInterval(wait_observer);
                HubObjects.Notify("ADD_FAVORITE_OBSERVER", produto.produto_id, (_value===0?-1:1));
            }
        }, 100);
    }
    //#endregion HubObjects


    return (
        <>
            <div className="BestSelling products-area pb-70">
                <div className="container">
                    <div className="section-title">
                        <h2>Produtos em Destaque</h2>
                    </div>
                    <div className="row">
                        
                        {
                            list.length > 0 ?
                                list.map((item, i) => (
                                    <div key={'destq'+i} className="col-sm-6 col-lg-3">
                                        <div className="products-item">
                                            <div className="top">
                                                <a className={item.favorite===0?'wishlist':'wishlist_selected'}  onClick={() => AddFavorite(item)}>
                                                    <i className='bx bx-heart'></i>
                                                </a>
                                                <img src={'https://painel.comercialwn.com.br/db/'+(item.foto===''?'semfoto.jpg':item.foto)} alt="Products" />
                                                <div className="inner">
                                                    <h3>
                                                        <LinkContainer to={'/Produto/'+item.produto_id+'/'+UrlFy(item.descricao)}>
                                                        <a>{item.descricao}</a>
                                                        </LinkContainer>
                                                    </h3>
                                                    <span className={(item.preco===0?'hide':'')}>{Currency(item.preco)}</span>
                                                    <span className={(item.preco!==0?'hide':'')}><small>Preço apenas para cadastrados</small></span>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <LinkContainer to={'/Produto/'+item.produto_id+'/'+UrlFy(item.descricao)}>
                                                <a className="cart-text" onClick={() => _HandleProdutoUpdate(item.produto_id)}>Mais Detalhes</a>
                                                </LinkContainer>
                                                <i className='bx bx-plus'></i>
                                            </div>
                                        </div>
                                    </div>
                            ))
                            : (<></>)
                        }

                        <div>
                            <small>*Possível ocorrer alterações nos preços sem aviso prévio, consulte nossos canais de vendas</small>
                        </div>
                           
                    </div>
                </div>
            </div>
        </>
    );
}