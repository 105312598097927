import React, { useEffect, useState, useRef }  from 'react';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Cache } from '../../../scripts/Cache';
import './index.css';

export default function Menu() {
    const [pageLoading, setPageLoading] = useState(false);
    const [categorias, setCategorias] = useState([]);

    const loadedRef = useRef(false);
    const navigate = useNavigate();

     //#region Effects
     useEffect(() => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            window.$('.mean-menu').meanmenu({meanScreenWidth:'991'});
            LoadCategorias();
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects


   //#region Loaders
   const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Home/Categorias", "GET", "", "", 3600*1000))
            .then((data) => {
                //console.log(data);
                setCategorias(data);
                setTimeout(() => { 
                    window.$("select[id='categories']").niceSelect();
                    window.$("#categories").on("change", function (e) {
                        _HandleCategoryChange(e);
                    });
                }, 100);
            }).catch(() => {
            
            });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleCategoryChange = (event) => {
        let _search = {categoria_id: event.target.value, word: ""};

        if(window.$("#produtos_div").length === 0) {
            Cache.JSet("search", _search);
            navigate("/Produtos");
        } else {
            HubObjects.Exec("HOME_PRODUCTS_SEARCH", _search);
        }
    }
    //#endregion Handlers



    return (
        <>
        <div className="Menu navbar-area sticky-top">
            <div className="mobile-nav">
                <a href="#" className="logo">
                    <img src="assets/images/logo.png" alt="Logo" />
                </a>
            </div>

            <div className="main-nav">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="left">
                            <select id="categories" className="niceselect" onChange={_HandleCategoryChange}>
                                <option value="">Categorias</option>
                                    {
                                        categorias.length > 0 ? 
                                            categorias.map((item, i) => (
                                                <option key={'cat_opt_'+i} value={item.categoria_id}>{item.nome}</option>
                                            ))
                                        : (<></>)
                                    }
                            </select>
                        </div>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <LinkContainer to="/Home">
                                        <a href="#" className="nav-link">Home</a>
                                    </LinkContainer>
                                </li>
                                <li className="nav-item">
                                    <LinkContainer to="/Produtos">
                                        <a href="#" className="nav-link">Produtos</a>
                                    </LinkContainer>
                                </li>
                                <li className="nav-item">
                                    <LinkContainer to="/Sobre">
                                        <a href="#" className="nav-link">Sobre nós</a>
                                    </LinkContainer>
                                </li>
                               
                                <li className="nav-item">
                                    <LinkContainer to="/Contato">
                                        <a href="#" className="nav-link">Contato</a>
                                    </LinkContainer>
                                </li>
                            </ul>
                            <div className="side-nav">
                                {/*<h4>Get <span>50%</span> Discount On Black Friday Offer <a href="products-on-sale.html">View
                                        Products On Sale</a></h4>*/}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        </>
    )
}