import React, { useEffect, useState, useRef, useContext }  from 'react';
import { Context } from '../../Context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';


import Breadcrumb from '../Header/Breadcrumb';
import BestSelling from './BestSelling'
import Info from './Info';


export default function Produto(props) {
    let { ProdutoId } = useParams();

    const list = [
        {link:"/", name:"Home"},
        {link:"", name:"Detalhes do produto"}
    ];

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    return (
        <>
            <Breadcrumb title="Produto" list={list}  />
            <Info ProdutoId={ProdutoId} />
            <BestSelling />
        </>
    )
}