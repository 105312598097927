/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect,useContext } from 'react';
import { FormGenerator } from '../../Form/FormGenerator';
import { Uuid, RemoveAcentos, Currency, IsCPF } from '../../../scripts/StringUtils';
import { Cache } from '../../../scripts/Cache.js';
import { LinkContainer } from 'react-router-bootstrap';
import { Request } from '../../../scripts/Request';
import { useNavigate } from 'react-router-dom';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { Context } from '../../../Context/AuthContext'
import './index.css';


export default function Formulario() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [saveable, setSaveable] = useState(true);
    const [input, setInput] = useState({});


    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _latlngRef = useRef({latitude: "", longitude:""});;

    const navigate = useNavigate();



    //#region Effects
    useEffect(() => {
        window.$('html, body').animate({
            scrollTop: window.$("body").offset().top
        }, 500);
    }, []);

    
    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
   
    //#endregion Loaders


    //#region Form Handlers
    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=" + value, "GET", { }, "");
        let _codigo_cidade = await _formUserRef.current.GetValue("cidade_codigo");
        cidade_codigo(_codigo_cidade);
        setPageLoading(false);
    }


    const cidade_codigo = async (value) => {
        let _uf_cidade = await _formUserRef.current.GetValue("uf_codigo").id;
    }



    const blur_email = async(args) => {
        let _cnpj = await _formUserRef.current.GetValue("cnpj");
        let _email = await _formUserRef.current.GetValue("email");

        if(_cnpj !== "" && _email !== "") {
            Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", {cnpj: _cnpj, email: _email}, "", 0))
                .then((data) => {
                    if(data.toString() === "exists") {
                        setSaveable(false);
                        window.swal("Alerta", "Usuário já existente", "warning");
                        _formUserRef.current.Value("cnpj", "");
                        _formUserRef.current.Value("email", "");
                    } else {
                        setSaveable(true);
                    }
                }).catch(() => {
                    
                });
        }
    }


    const blur_cnpj = async(args) => {
        if(cnpj.isValid(args)) {
            let _cnpj = await _formUserRef.current.GetValue("cnpj");
            let _email = await _formUserRef.current.GetValue("email");

            if(_cnpj  !== "" && _email !== "") {
                Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", {cnpj: _cnpj, email: _email}, "", 0))
                    .then((data) => {
                        if(data.toString() === "exists") {
                            setSaveable(false);
                            window.swal("Alerta", "Usuário já existente", "warning");
                            _formUserRef.current.Value("cpf", "");
                            _formUserRef.current.Value("email", "");
                        } else {
                            setSaveable(true);
                        }
                    }).catch(() => {
                        
                    });
            }
       } else {
            window.swal("Alerta", "CNPJ incorreto!", "warning");
        }
    }


    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=26", "GET", { }, "");
                let arr = _formUserRef.current.GetOptions("cidade_codigo");
                _formUserRef.current.Value("cidade_codigo", arr[0].id);

                if(user.cliente_id !== "") {
                    let user_data = await new Request().Run("api/Cadastro/Load?cliente_id=" + user.cliente_id, "POST", {}, user.token);
                    await _formUserRef.current.Load(user_data);
                }
            }, 200);
        } catch(e) { }
        setPageLoading(false);
    }



    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
      
        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }


        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Cadastro/Save", {cliente_id: user.cliente_id, guid: user.guid}, user.token);
        setPageLoading(false);
        

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Seu cadastro foi realizado! Aguarde o processo de validação e liberação do seu cadastro", "success");
            setTimeout(() => { 
                if(user.token === '') {
                    navigate('/Login'); 
                } else {
                    navigate('/');
                }
            }, 300);
            
        } else if(_result.toString() === "exists") {
            window.swal("Alerta", "Usuário já existente", "warning");
        } else {
            window.swal("Alerta", "Não foi possível cadastrar o usuário", "warning");
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers

    //#endregion Handlers





    //#region Tests
     /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            F("nome", "ComercialWN");
            F("email", "contato@comercialwm.com.br");
            F("telefone", "(12) 3624-3844");
            F("whatsapp", "(12) 98122-1043");
            F("cnpj", "07.368.378/0001-91");
            await F("uf_codigo", "26");
            await F("cidade_codigo", "9726");
            F("endereco", "Rua cap Antonio Raposo Barreto");
            F("numero", "17");
            F("cep", "12091530");
            F("bairro", "Jardim Bela Vista");
            F("complemento", "residencia");
        }
    });*/


    const F =  async(key, val) => {
        try {
            await _formUserRef.current.Value(key, val);
        } catch(e) {}
    }

    const R = (key, val) =>{
        window.$('input:radio[name="'+key+'"]').filter('[value="' + val + '"]').attr('checked', true);
    }

    const D =  async(key, val) => {
        await window.$("#"+key).val(val);
    }

    const C =  async(key, val) => {
        await window.$("#"+key).attr('checked', val);
    }
    //#endregion Tests



    return (
        <div className="Formulario user-area ptb-100">
            <div className="container">
                <div className="user-item">
                    <form name="fuser" onSubmit={(event) => _HandleSubmit('rcadastro', event)} autoComplete="off" id="form-contact" className="" noValidate>
                        <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/cadastro.json'} execRef={Exec}></FormGenerator>
                        <div style={{clear:'both'}}></div>
                        <br /><br />

                       <button type="submit" class="btn common-btn">
                            {user.token===''?'Cadastrar':'Atualizar'}
                            <img src="/assets/images/shape1.png" alt="Shape" />
                            <img src="/assets/images/shape2.png" alt="Shape" />
                        </button>
                    </form>
                    <hr />
                    <br />
                    <p><center>Já é cadastrado ? <LinkContainer to="/Login"><a href="#"> Faça seu Login </a></LinkContainer> </center></p>
                </div>
            </div>
        </div>
      
    )
}