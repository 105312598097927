import React, { useEffect, useState, useRef, useContext}  from 'react';
import { Request } from '../../../scripts/Request';
import { Cache } from '../../../scripts/Cache';
import { HubObjects } from '../../../scripts/HubObjects';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../Context/AuthContext'
import './index.css';



export default function Search() {
    const component_name = "header/search";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [currentAuthenticated, setCurrentAuthenticated] = useState(false);
    const [search, setSearch]= useState({categoria_id:"", word: ""});
    const [favorites, setFavorites]= useState(0);

    const loadedRef = useRef(false);
    const navigate = useNavigate();

    const userRef = useRef(user);

    //#region Effects
    useEffect(() => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            LoadCategorias();
            RegisterHub();
        }
    }, []);


    useEffect(() => {
        userRef.current = user;
        //console.log(1, userRef.current);
        setCurrentAuthenticated(authenticated);
        if(userRef.current.cliente_id !== ""){
            LoadFavorites();
        } else {
            setFavorites(0);
        }
    }, [user])


    useEffect(() => {
        if(pageLoading){
          window.$('.loader').fadeIn(500);
        } else {
          window.$('.loader').fadeOut(500);
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Home/Categorias", "GET", "", "", 3600*1000))
            .then((data) => {
                //console.log(data);
                setCategorias(data);
                setTimeout(() => { 
                    window.$("select[id='categoria_id']").niceSelect();
                    window.$("#categoria_id").on("change", function (e) {
                        _HandleSearchChange(e);
                    });
                 }, 100);
            }).catch(() => {
            
            });
    }


    const LoadFavorites = () => {
        Promise.resolve(new Request().Run("api/Home/Favorites", "GET", "", userRef.current.token, 0*1000))
            .then((data) => {
                setFavorites(data);
            }).catch(() => {
            
            });
    }


    const AddFavorite = (produto_id, value) => {
        Promise.resolve(new Request().Run("api/Home/AddFavorite", "POST", {produto_id: produto_id, cliente_id:  userRef.current.cliente_id, value: (value===-1?0:1)}, userRef.current.token, 0*1000))
            .then((data) => {
                
            }).catch(() => {
            
            });
    }
    //#endregion Loaders


    //#region HubObjects
    const RegisterHub = () => {
        /*HubObjects.Set("SEARCH_ADD_FAVORITE", produto_id => {
            if(user.token === "") {
                window.swal("Alerta", "Para adicionar favoritos você precisa estar logado", "error");
            }
        }, component_name);*/

        HubObjects.Observer("ADD_FAVORITE_OBSERVER");

        let wait_observer = setInterval(()=> {
            if(HubObjects.ObserverExists("ADD_FAVORITE_OBSERVER")) {
                clearInterval(wait_observer);
                HubObjects.Subscribe("ADD_FAVORITE_OBSERVER", (produto_id, value) => { 
                    if(userRef.current.token === "") {
                        window.swal("Alerta", "Para adicionar favoritos você precisa estar logado", "error");
                    } else {
                        setFavorites(prev => prev + value);
                        AddFavorite(produto_id, value)
                    }
                });
            }
        }, 100)

    };
    //#endregion HubObjects


    //#region Handlers
    const _HandleSearchChange = (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);
    }

    const _HandleSearchSubmit = (event) => {
        event.preventDefault();
        let _search = Object.assign({}, search);

        if(window.$("#produtos_div").length === 0) {
            Cache.JSet("search", _search);
            navigate("/Produtos");
        } else {
            HubObjects.Exec("HOME_PRODUCTS_SEARCH", _search);
        }
    }


    const _HandleShowFavorites = () => {
        if(userRef.current.token === "") {
            window.swal("Alerta", "Para acessar seu favoritos você precisa estar logado", "error");
        } else {
            if(window.$("#produtos_div").length === 0) {
                Cache.JSet("search", {categoria_id:"", word: "", favorites: 1});
                navigate("/Produtos");
            } else {
                HubObjects.Exec("HOME_PRODUCTS_SEARCH", {categoria_id:"", word: "", favorites: 1});
            }

            window.$('html, body').animate({
                scrollTop: window.$("#produtos_div").offset().top
            }, 500);
        } 
    }


    const _handleLogout = () => {
        handleLogout(true);
        setPageLoading(true);
        setTimeout(()=> {
            setPageLoading(false)
            let _search = Object.assign({}, search);
            HubObjects.Exec("HOME_PRODUCTS_SEARCH", _search);
            navigate("/");
        }, 300);
    }
    //#endregion Handlers


    return (
        <>
        <div className="nav-top-area">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-2">
                        <div className="left">
                            <LinkContainer to="/">
                            <a href="#">
                                <img src="/assets/images/logo.png" alt="Logo" />
                            </a>
                            </LinkContainer>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="middle">
                            <form name="fsearch" onSubmit={_HandleSearchSubmit} autoComplete="off" noValidate>
                                <div className="form-group">
                                    <div className="inner">
                                        <select id="categoria_id" className="niceselect"  value={search.categoria_id} onChange={_HandleSearchChange}>
                                            <option value="">Categorias</option>
                                                {
                                                    categorias.length > 0 ? 
                                                        categorias.map((item, i) => (
                                                            <option key={'cat_opt_'+i} value={item.categoria_id}>{item.nome}</option>
                                                        ))
                                                    : (<></>)
                                                }
                                        </select>
                                    </div>
                                    <input type="text" id="word" className="form-control" placeholder="Digite o que deseja buscar" value={search.word} onChange={_HandleSearchChange} autoComplete="off"/>
                                    <button type="submit" className="btn">
                                        <i className='bx bx-search'></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="right">
                            <ul>
                     
                                <li>
                                    <button type="button" className="btn wishlist" data-bs-toggle="modal" onClick={_HandleShowFavorites}
                                        data-bs-target="#exampleModalWishlist" data-bs-whatever="@mdo">
                                        <i className='bx bx-heart'></i>
                                        <span className={favorites===0?'_hide':''}>{favorites}</span>
                                    </button>
                                </li>
                                <li>
                                    { userRef.current.token === '' ? (
                                        <LinkContainer to="/Login">
                                        <a className="join" href="#">
                                            <i className='bx bxs-user'></i>
                                            &nbsp; Login
                                        </a>
                                        </LinkContainer>) : (

                                         <a className="join" href="#" onClick={_handleLogout}>
                                         <i className='bx bxs-left-arrow-alt'></i>
                                         &nbsp; Logout
                                     </a>
                                    )}
                                </li>
                                <li>
                                    <LinkContainer to="/Cadastro">
                                    <a className="join" href="l#">
                                        <i className='bx bx-edit-alt'></i>
                                        &nbsp; Cadastro
                                    </a>
                                    </LinkContainer>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </>
    );
}