import React, { useEffect, useState, useRef, useContext }  from 'react';
import { Context } from '../../Context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';


import Breadcrumb from '../Header/Breadcrumb';
import Formulario from './Formulario';


export default function Contato() {


    const list = [
        {link:"/", name:"Home"},
        {link:"", name:"Formuário de contato"}
    ];

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    return (
        <>
            <Breadcrumb title="Contato" list={list}  />
            <Formulario />
        </>
    )
}